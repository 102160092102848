<template>
  <div ref="holder" class="editorjs course-content"></div>
</template>

<script setup>
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Paragraph from '@editorjs/paragraph'
import Attaches from '@editorjs/attaches'
import Delimiter from '@editorjs/delimiter'
import Image from '@editorjs/image'
import List from '@editorjs/list'
import Marker from '@editorjs/marker'

const props = defineProps(['editorData', 'placeholder', 'readOnly'])
const emit = defineEmits(['init'])

const config = useRuntimeConfig()
const token = useTokenCookie()

const editor = ref(null)
const holder = ref(null)

onMounted(async () => {
  const editorJS = new EditorJS({
    holder: holder.value,
    tools: {
      header: {
        class: Header,
        inlineToolbar: true
      },
      paragraph: {
        class: Paragraph,
        inlineToolbar: true
      },
      // checklist,
      // code,
      delimiter: Delimiter,
      // embed,
      // link,
      list: {
        class: List,
        inlineToolbar: true
      },
      marker: Marker,
      // personality,
      // quote,
      // raw,
      // table,
      // warning,
      attaches: {
        class: Attaches,
        config: {
          endpoint: `${config.public.baseURL}/materials/files/editor`,
          field: 'file',
          additionalRequestHeaders: {
            Accept: 'application/json',
            Authorization: `Bearer ${token.value}`
          }
        }
      },
      image: {
        class: Image,
        config: {
          endpoints: {
            byFile: `${config.public.baseURL}/materials/files/editor`,
            byUrl: `${config.public.baseURL}/materials/files/editor`
          },
          field: 'file',
          additionalRequestHeaders: {
            Accept: 'application/json',
            Authorization: `Bearer ${token.value}`
          }
        }
      }
    },
    i18n,
    data: props.editorData,
    placeholder: props.placeholder ?? 'Это редактор текста. Здесь можно что-то написать.',
    readOnly: !!props.readOnly
  })

  await editorJS.isReady
  editor.value = editorJS
  updateEditorContent(props.editorData)

  emit('init', editor.value)
})

async function updateEditorContent (content) {
  if (!editor.value) return
  if (content !== null && typeof content === 'object' && content.blocks?.length > 0) {
    await editor.value.render(content)
  } else {
    await editor.value.clear()
  }
}

watch(() => props.editorData, updateEditorContent, { immediate: true })

onBeforeUnmount(() => {
  editor.value.destroy()
  editor.value = null
})

const i18n = {
  /**
   * @type {I18nDictionary}
   */
  messages: {
    /**
     * Other below: translation of different UI components of the editor.js core
     */
    ui: {
      blockTunes: {
        toggler: {
          'Click to tune': 'Нажмите, чтобы настроить',
          'or drag to move': 'или перетащите'
        }
      },
      inlineToolbar: {
        converter: {
          'Convert to': 'Конвертировать в'
        }
      },
      toolbar: {
        toolbox: {
          Add: 'Добавить'
        }
      },
      popover: {
        Filter: 'Поиск',
        'Nothing found': 'Ничего не найдено'
      }
    },

    /**
     * Section for translation Tool Names: both block and inline tools
     */
    toolNames: {
      Filter: 'Поиск',
      Text: 'Параграф',
      Heading: 'Заголовок',
      List: 'Список',
      Warning: 'Примечание',
      Checklist: 'Чеклист',
      Quote: 'Цитата',
      Code: 'Код',
      Delimiter: 'Разделитель',
      'Raw HTML': 'HTML-фрагмент',
      Table: 'Таблица',
      Link: 'Ссылка',
      Marker: 'Маркер',
      Bold: 'Полужирный',
      Italic: 'Курсив',
      InlineCode: 'Моноширинный',
      Image: 'Картинка',
      Attachment: 'Файл',
      Personality: 'Персона'
    },

    /**
     * Section for passing translations to the external tools classes
     */
    tools: {
      /**
       * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
       * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
       */
      warning: { // <-- 'Warning' tool will accept this dictionary section
        Title: 'Название',
        Message: 'Сообщение'
      },

      /**
       * Link is the internal Inline Tool
       */
      link: {
        'Add a link': 'Вставьте ссылку'
      },
      /**
       * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
       */
      stub: {
        'The block can not be displayed correctly.': 'Блок не может быть отображен'
      },
      image: {
        Caption: 'Подпись',
        'Select an Image': 'Выберите файл',
        'With border': 'Добавить рамку',
        'Stretch image': 'Растянуть',
        'With background': 'Добавить фон'
      },
      code: {
        'Enter a code': 'Код'
      },
      linkTool: {
        Link: 'Ссылка',
        'Couldn\'t fetch the link data': 'Не удалось получить данные',
        'Couldn\'t get this link data, try the other one': 'Не удалось получить данные по ссылке, попробуйте другую',
        'Wrong response format from the server': 'Неполадки на сервере'
      },
      header: {
        Header: 'Заголовок'
      },
      paragraph: {
        'Enter something': 'Введите текст'
      },
      list: {
        Ordered: 'Нумерованный',
        Unordered: 'Маркированный'
      }
    },

    /**
     * Section allows to translate Block Tunes
     */
    blockTunes: {
      /**
       * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
       * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
       *
       * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
       */
      delete: {
        Delete: 'Удалить',
        'Click to delete': 'Подтвердите'
      },
      moveUp: {
        'Move up': 'Сдвинуть вверх'
      },
      moveDown: {
        'Move down': 'Сдвинуть вниз'
      }
    }
  }
}
</script>

<style lang="scss">
.editorjs {
  border: 1px solid var(--color-elements-tertiary);
  border-radius: 5px;
  background: var(--color-bg);
  .codex-editor__redactor {
    margin-right: 0;
  }

  .cdx-attaches {
    --color-bg: var(--color-white);
    --color-line: var(--color-white);
  }

  .ce-popover {
    background: var(--color-bg);
    border-color: var(--color-bg-secondary);

    &__item {
      &:hover {
        background: var(--color-bg-secondary);
      }
    }
    &__item-icon {
      background: var(--color-bg);
      box-shadow: 0 0 0 1px var(--color-bg-secondary);
    }
  }

  .cdx-search-field {
    background: var(--color-bg-secondary);
  }

  .ce-toolbar__plus, .ce-toolbox__button {
    color: var(--color-text-dimmed);

    &--active, &:hover {
      color: var(--color-accent);
    }
  }

  .ce-toolbar__settings-btn {
    background-color: var(--color-bg);
    color: var(--color-separator);

    &:hover {
      color: var(--color-text-dimmed);
    }
  }

  .ce-settings {
    background-color: var(--color-bg);
    border: none;
    z-index: 9999;

    &__button, .cdx-settings-button {
      color: var(--color-text-dimmed);
      opacity: 1;

      &:hover {
        background-color: var(--color-secondary);
      }

      &--active {
        color: var(--color-accent);
      }

      &--confirm {
        background-color: var(--color-error);
        color: var(--color-white);
      }
    }
  }

  .ce-inline-toolbar {
    background-color: var(--color-bg);
    border: none;

    &__dropdown {
      border-radius: 4px 0 0 4px;
      border-color: var(--color-secondary);
      color: var(--color-text-dimmed);

      &:hover {
        background-color: var(--color-secondary);
      }
    }

    .ce-inline-tool {
      color: var(--color-text-dimmed);
      opacity: 1;

      &:hover {
        background-color: var(--color-secondary);
      }

      &--active {
        color: var(--color-accent);
      }
    }
  }

  .ce-conversion-toolbar {
    background-color: var(--color-bg);
    border: none;

    &__label {
      color: var(--color-text-dimmed);
    }

    .ce-conversion-tool {
      &:hover {
        background-color: var(--color-secondary);
      }

      &--focused {
        background-color: var(--color-separator) !important;
        box-shadow: none;
      }

      &__icon {
        background: none;
      }
    }
  }

  .ce-header {
    margin-top: 0;
    margin-bottom: -0.5em;
    padding: 0.5em 0;
    text-transform: none;
  }

  .ce-paragraph {
  }

  .ce-block--selected .ce-block__content {
    background-color: var(--color-accent);
    color: var(--color-white);
    transition-property: background-color, color;
  }

  .ce-paragraph[data-placeholder]:empty::before {
    color: var(--color-text-dimmed);
  }

  .ce-delimiter {
    color: var(--color-text-dimmed);
  }

  .cdx-list {
    li {
      margin-bottom: 0;

      &::before {
        top: 0.9em;
      }
    }
  }

  ol.cdx-list {
    padding-left: 20px;

    li {
      list-style: inherit;
    }
  }

  .cdx-button {
    border-color: var(--color-separator);
    background-color: var(--color-bg);
    color: var(--color-text);
  }

  .cdx-input {
    border-color: var(--color-separator);
    background-color: var(--color-bg);
    color: var(--color-text);

    &[contentEditable=true][data-placeholder]::before {
      color: var(--color-text-dimmed);
    }
  }

  .image-tool {
    --bg-color: var(--color-secondary);
    --front-color: var(--color-accent);
    --border-color: var(--color-separator);

    &__image {
      background-color: var(--color-bg);
    }
  }

  .link-tool {
    &__input-holder {
      background-color: var(--color-bg);
      border-radius: 4px 0 0 4px;
      overflow: hidden;
    }

    &__progress {
      background: var(--color-secondary);
      z-index: 0;
    }

    &__input {
      background-color: transparent;
      position: relative;

      [data-theme=dark] & {
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.567 13.629c.728.464 1.581.65 2.41.558l-.873.873A3.722 3.722 0 1 1 4.84 9.794L6.694 7.94a3.722 3.722 0 0 1 5.256-.008L10.484 9.4a5.209 5.209 0 0 1-.017.016 1.625 1.625 0 0 0-2.29.009l-1.854 1.854a1.626 1.626 0 0 0 2.244 2.35zm2.766-7.358a3.722 3.722 0 0 0-2.41-.558l.873-.873a3.722 3.722 0 1 1 5.264 5.266l-1.854 1.854a3.722 3.722 0 0 1-5.256.008L9.416 10.5a5.2 5.2 0 0 1 .017-.016 1.625 1.625 0 0 0 2.29-.009l1.854-1.854a1.626 1.626 0 0 0-2.244-2.35z' fill='rgba(255, 255, 255, 0.6)' transform='translate(-3.667 -2.7)'/%3E%3C/svg%3E%0A")
      }
    }
  }
}

.ct {
  font-family: Inter, sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &::before, &::after {
    background-color: var(--color-school-outline-card-bg);
  }

  &__content {
    color: var(--color-text-dimmed);
  }
}

.cdx-notifies {
  font: inherit;
  font-family: Inter, sans-serif;
}

.cdx-notify {
  background-color: var(--color-bg) !important;
  color: var(--color-text);

  //&--error {
  //
  //}
  //&--bounce-in {
  //
  //}

  &__cross {
    width: 18px;
    height: 18px;
  }
}
</style>
